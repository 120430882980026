/**
 * TtfOS2Table.ts class for EJ2-PDF
 * The OS/2 table consists of a set of metrics that are required by Windows and OS/2.
 */
var TtfOS2Table = /** @class */ (function () {
    function TtfOS2Table() {
    }
    return TtfOS2Table;
}());
export { TtfOS2Table };
